<template>
  <div :bordered="false" :style="{ height: '100%' }">
    创建权限组
    <a-row :gutter="24">
      <a-col :md="18" :offset="6">
        <div style="max-width: 800px">
          <a-divider v-if="false" />
          <div v-if="mdl.id">
            <h3>角色：{{ mdl.name }}</h3>
          </div>
          <a-form :form="form" :layout="false? 'vertical' : 'horizontal'">
            <a-form-item label="权限组名称">
              <a-input v-decorator="[ 'name', {rules: [{ required: true, message: '请填写权限组名称' }]} ]" placeholder="请填写权限组名称" />
            </a-form-item>

            <a-form-item label="站点">
              <a-select v-decorator="[ 'site', {rules: [], initialValue: 1} ]" @select="changePermission">
                <a-select-option :value="1">管理端</a-select-option>
                <a-select-option :value="2">企业端</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="备注说明">
              <a-textarea :row="3" v-decorator="[ 'description', {rules: [{ required: false, message: '请输入' }]} ]" placeholder="请输入" />
            </a-form-item>

            <a-form-item label="拥有权限">
              <a-row :gutter="16" v-for="(permission, index) in permissions" :key="index+1">
                <a-col :xl="4" :lg="24">
                  {{ permission.name }}：
                </a-col>
                <a-col :xl="20" :lg="24">
                  <a-checkbox
                    v-if="permission.actionsOptions.length > 0"
                    :indeterminate="permission.indeterminate"
                    :checked="permission.checkedAll"
                    @change="onChangeCheckAll($event, permission)">
                    全选
                  </a-checkbox>
                  <a-checkbox-group :options="permission.actionsOptions" v-model="permission.selected" @change="onChangeCheck(permission)" />
                </a-col>
              </a-row>
            </a-form-item>

            <a-form-item>
              <a-row>
                <a-col span="6">
                  <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
                </a-col>
                <a-col span="10">
                  <a-button @click="handleGoBack">返回</a-button>
                </a-col>
                <a-col span="8"></a-col>
              </a-row>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { baseMixin } from '@/store/app-mixin'
import { permission_group_create } from '@/api/permission_group'
import { permissionNoPager } from '@/views/a-role/table/role'

export default {
  name: 'RoleList',
  mixins: [baseMixin],
  components: {},
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      mdl: {},
      roles: [],
      permissions: []
    }
  },
  created () {
    console.log(this.record, '我是穿过的数据')
    this.loadPermissions(1)
    this.edit(this.record)
  },
  methods: {
    changePermission () {
      console.log(111111111)
      const site = this.form.getFieldValue('site')
      this.loadPermissions(site)
    },
    handleSubmit () {
      const form = this.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        console.log(errors)
        console.log(values)
        if (!errors) {
          const rolepermissons = this.permissions.map((item) => {
           return {
              roleId: values.id,
              permissionId: item.id,
              permissionName: item.name,
              actions: item.actionData,
              actionEntitySet: item.actionsOptions.map((i) => {
                          if (item.selected.indexOf(i.value) !== -1) {
                            return {
                              action: i.value,
                              description: i.label,
                              defaultCheck: true,
                              pid: i.pid
                            }
                          } else {
                            return {
                              action: i.value,
                              description: i.label,
                              defaultCheck: false
                            }
                          }
              }),
              actionList: item.selected,
              dataAccess: null
           }
          })
          const actionEntitySetlist = rolepermissons.map((item) => {
            return item.actionEntitySet
          })
          const flatactionEntitySetlist = actionEntitySetlist.flat().map((item) => {
            return item.pid
          })
          const permission_ids = flatactionEntitySetlist.filter((item) => {
            return item
          })
          const rolepermissons_filter = rolepermissons.filter((item) => {
            return item.actionList.length > 0
          })
          console.log(rolepermissons_filter, '-------')
          const endData = JSON.stringify(rolepermissons_filter)
          values.corporation_id = -1
          const params = Object.assign({}, values, { view_json: endData, permission_ids: permission_ids })
          // 新增
          if (params) {
            console.log(params)
          }
          permission_group_create(params).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.handleGoBack()
          }, (errors) => {
            this.confirmLoading = false
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    callback (val) {
      console.log(val)
    },
    edit (record) {
      this.mdl = Object.assign({}, record)
      // 有权限表，处理勾选
      if (this.mdl.permissions && this.permissions) {
        // 先处理要勾选的权限结构
        const permissionsAction = {}
        this.mdl.permissions.forEach(permission => {
          permissionsAction[permission.permissionId] = permission.actionEntitySet.map(entity => entity.action)
        })
        // 把权限表遍历一遍，设定要勾选的权限 action
        this.permissions.forEach(permission => {
          const selected = permissionsAction[permission.id]
          permission.selected = selected || []
          this.onChangeCheck(permission)
        })
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.mdl, 'id', 'name', 'site', 'description'))
      })
    },
    onChangeCheck (permission) {
      permission.indeterminate = !!permission.selected.length && (permission.selected.length < permission.actionsOptions.length)
      permission.checkedAll = permission.selected.length === permission.actionsOptions.length
    },
    onChangeCheckAll (e, permission) {
      Object.assign(permission, {
        selected: e.target.checked ? permission.actionsOptions.map(obj => obj.value) : [],
        indeterminate: false,
        checkedAll: e.target.checked
      })
    },
    // 获取全量的菜单
    loadPermissions (site) {
      console.log(site)
      this.permissions = permissionNoPager.map(permission => {
        const options = JSON.parse(permission.actionData)
        permission.checkedAll = false
        permission.selected = []
        permission.indeterminate = false
        permission.actionsOptions = options.map(option => {
          return {
            label: option.description,
            value: option.action,
            pid: option.pid
          }
        })
        return permission
      })
      // eslint-disable-next-line eqeqeq
      if (site == 1) {
        this.permissions = this.permissions.filter((item) => {
          return item.site === 'admin'
        })
        console.log(this.permissions)
      }
      // eslint-disable-next-line eqeqeq
      if (site == 2) {
        this.permissions = this.permissions.filter((item) => {
          return item.site === 'corporation'
        })
        console.log(this.permissions)
      }
    }
  }
}
</script>

<style scoped>

</style>
